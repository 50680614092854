export const fields = [
  { value: 'delayedDays', text: 'Dias em Atraso' },
]

export const operations = [
  { value: 'gte', text: 'Maior ou Igual' },
  { value: 'gt', text: 'Maior' },
  { value: 'lte', text: 'Menor ou Igual' },
  { value: 'lt', text: 'Menor' },
  { value: 'eq', text: 'Igual' },
  { value: 'ne', text: 'Não Igual' },
]
