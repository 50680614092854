<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-plain p-button-text mr-2"
          @click="$router.push('/clientes/list')" />
        <span class="text-primary text-5xl">/</span> Clientes
        <span class="text-primary text-5xl">/</span> Regras
      </div>
      <div class="text-500 mb-5">Listagem de regras de clientes</div>

      <div class="card">
        <Toast />
        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filters.filter" placeholder="Pesquisar" @add="loadRecords" @remove="loadRecords" />
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"
              @click="$router.push(`/clientes/${this.$route.params.client}/regras/new`)" />
          </div>
        </div>

        <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="id" header="#"></Column>

          <Column field="field" header="Campo">
            <template #body="slotProps">
              {{ translateField(slotProps.data.field) }}
            </template>
          </Column>

          <Column field="operation" header="Operação">
            <template #body="slotProps">
              {{ translateOperation(slotProps.data.operation) }}
            </template>
          </Column>

          <Column field="value" header="Valor">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.value) }}
            </template>
          </Column>

          <Column field="percentage" header="Desconto">
            <template #body="slotProps">
              {{ slotProps.data.percentage }} %
            </template>
          </Column>

          <Column bodyClass="text-right">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-text p-button mr-2"
                @click="$router.push(`/clientes/${$route.params.client}/regras/${slotProps.data.id}`)" />
              <Button icon="pi pi-trash" class="p-button-text p-button mt-2"
                @click="confirmDeleteRecord(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
              @click="deleteRecord" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CrudList from '../../../components/abstract/CrudList.vue'
import CrudService from '@/services/crud'
import { fields, operations } from '@/services/translate'

export default {
  extends: CrudList,
  data() {
    return {
      service: new CrudService(`/admin/clientes/${this.$route.params.client}/regras`),
    }
  },
  methods: {
    translateField(field) {
      const fieldObj = fields.find(item => item.value === field)
      return (fieldObj && fieldObj.text) || 'ERROR'
    },
    translateOperation(operator) {
      const operatorObj = operations.find(item => item.value === operator)
      return (operatorObj && operatorObj.text) || 'ERROR'
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      return
    }
  }
}
</script>

<style scoped lang="scss">

</style>
